import _logo from './logo.svg';
import _logoFull from './logo_full.svg';
import _Contraband from './Contraband';
import _Insurance from './Insurance';

export const LOGO = _logo;
export const LOGO_FULL = _logoFull;
export const Contraband = _Contraband;
export const Insurance = _Insurance;

export { SHIPPING_SERVICES } from './shipping_services';

export const TELEGRAM_BOT_API_KEY = '1766509050:AAE31HIH4ee4qaXIinRro7CYvEAfeYwedSQ';
export const TELEGRAM_BOT_CHAT_ID = -1001233856461;
export const TELEGRAM_BOT_URI = `https://api.telegram.org/bot${TELEGRAM_BOT_API_KEY}/sendMessage`;

export const COMPANY_FULL_NAME = '界迹国际转运';
export const COMPANY_SHORT_NAME = '界迹';
export const COMPANY_WECHAT_ID = 'hanj828';
export const COMPANY_WORKING_HOURS = '周一至周日 7:00AM-2:00AM';
export const COMPANY_PACKING_DAYS_GUARANTEE = 2;
export const WAREHOUSE_ADDRESS = '福建省 福州市 长乐区 航城街道里仁工业区航进路268号2幢（界迹国际物流福州仓）';
export const WAREHOUSE_PHONE = '13665082995';
export const WAREHOUSE_ZIP_CODE = '350299';

export const COUNTRIES_SUPPORTED = {
  US: '美国',
  CA: '加拿大',
  JP: '日本',
};

export const CURRENCIES_SUPPORTED = {
  CNY: '人民币',
  USD: '美元',
};

type PaymentInfoType = {
  show: boolean,
  alipay?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
  taobao?: {
    enabled: boolean,
    link?: string,
  },
  wechatPay?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
  paypal?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
};

export const PAYMENT_INFO: PaymentInfoType = {
  show: true,
  alipay: {
    enabled: true,
    account: '18900289971',
    accountName: '金明翰',
  },
  wechatPay: {
    enabled: true,
    account: 'hanj828',
    accountName: '金明翰',
  },
  paypal: {
    enabled: false,
    account: '897006758@qq.com',
    accountName: '金明翰',
  },
};

export const ADMIN_LOGIN_PAGE_SHOW_LOGO = false;
export const ADMIN_LOGIN_PAGE_SHOW_COMPANY_NAME = true;
export const TICKET_SYSTEM_ENABLED = false;
export const SHOW_INSURANCE_PAGE = true;
export const EMAIL_NOTIFICATION_ENABLED = true;
export const ORDER_UPLOAD_IMAGE = true;
export const PACKAGE_UPLOAD_IMAGE = true;
export const DIRECT_ORDER_SELECT_SERVICE_ENABLED = true;
export const SHOW_SHIPPING_ROUTE_PAGE = true;
export const SHOW_CURRENCY_OPTION_IN_ORDER_PAGE = true;

export const PRICE_PER_PACKAGE = 0.2;
