import React from 'react';
import { Card } from 'antd';

import PageTitle from '../../components/PageTitle';

import './index.css';

const content = `
1. 丢货三倍运费赔付，包裹按实际申报价值赔付（丢货后客户提供购物凭证，每票最高赔付500美金）。

2. 扣关包裹我们会协助清关直到派送为止，有问题的包裹不会不了了之。

3. 高价值的货品不建议拼团，快递直邮更有保障。

`;

function Insurance() {
  return (
    <div className="Insurance">
      <PageTitle>丢失保险</PageTitle>

      <Card bordered={false}>
        <div className="Insurance-content">{content}</div>
      </Card>
    </div>
  );
}

export default Insurance;
