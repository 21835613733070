import React from 'react';
import { Card, Alert } from 'antd';
import '../../pages/user/Tutorial/index.css';

function Contraband() {
  return (
    <div className="Tutorial">
      <Alert
        showIcon
        type="warning"
        message="有疑问的话可以微信咨询你的操作员。仓库打包时若发现有违禁品，会移出订单并备注"
        style={{ marginBottom: '1rem' }}
      />

      <Card bordered={false} title="(美国)海运普货" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/6000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
          非国外品牌服装、鞋子、包包、饰品，中文书（非宗教信仰、政治类），不带电玩具（如电池可拆卸则可以拆出后运），小件床上用品（四件套等），部分家居类（如窗帘，小的收纳盒，浴巾、毛巾、地毯），烘焙模具，厨房工具（厨房神器、锅具等），餐具类刀（沙拉刀等），早教机、有声书（拆电池），玻璃制品（易碎类的需要包装好），少量陶瓷（一两件），爬行垫（注意尺寸限制），电子手表（小电池）等等
          </p>
          <p>
            尺寸要求：任何最长边不要超过55，次长边不要超过50。如果有尺寸比较大的包裹，请单独咨询客服。
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
          所有防疫用品（口罩、防护服等，不论是不是医用），液体（极少量也算），药品，粉末，香烟，烟丝，膏体，电池(干电池，锂电池/可充电电池等)，磁铁（包括带有电机、喇叭和其他有磁性的物品，如冰箱贴），酒精，颜料，涂料，腐蚀性，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），原木制品，文物，钱币，刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），食品，化妆品，磁性玩具，名牌，A货，英文书籍，部分家居类（大件床上用品及所有家具），蜡烛制品，太阳能产品（不论是太阳能板还是带太阳能功能），一次性用品（纸袋，塑料袋，垃圾袋，购物袋），大量陶瓷制品（包括餐具），眼镜，汽车、摩托车用品（凡是与汽车、摩托车相关联的任何产品，车载手机支架可以），铅笔，中性笔，水彩笔，水彩/粉颜料，吸管，洗牙器，牙粉，牙线，螺丝，蓝牙产品（蓝牙玩具，蓝牙耳机、蓝牙键盘，除非是可以拆除的干电池），昂贵饰品。该渠道为包税线路，如有禁运品被查到需要征收高额关税以及罚款
          </p>
        </div>
      </Card>

      <Card bordered={false} title="(美国)海运食品" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/6000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
          该渠道要求较为宽松，大部分海运普货禁运的东西（除去以下禁运的）都可以考虑走该渠道。非液体、非白色粉状、非膏状物、保质期长的食品（6 个月以上），方便面，辣椒面等，足贴，片状面膜，眼影，口红，唇膏，散粉等化妆品，非医用口罩等。隐形眼镜、指甲油这种少量液体的拼邮风险大，需要的话可以选择直邮(冲关试试，有被退回风险，要收退货费)
          </p>
          <p>
            尺寸要求：任何最长边不要超过55，次长边不要超过50。如果有尺寸比较大的包裹，请单独咨询客服。
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
          带医用字样的防疫用品（口罩、防护服等），腐蚀性，酒精，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），带电池的（干电池，锂电池/可充电电池等，除非电池非常小），液体，白色粉状，膏状，油脂、燕窝、肉制品和药品，含液体膏状物的化妆品（乳液，洗面奶，洁面啫喱，卸妆油，膏状面膜泥，指甲油，粉底液等）
          </p>
        </div>
      </Card>

      <Card bordered={false} title="(美国)海运药品" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/6000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
          可接各类药品，保健品，液体，粉末。
          </p>
          <p>
            尺寸要求：任何最长边不要超过55，次长边不要超过50。如果有尺寸比较大的包裹，请单独咨询客服。
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
          腐蚀性，酒精，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪）等具有严格管控性和危险性的产品。
          </p>
        </div>
      </Card>

      <Card bordered={false} title="(美国)空运普货" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/6000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
          日常用品：服装、图书、玩具、家居、饰品、家用电器（不带电池、马达、喇叭，非磁性）等
品牌产品：仅限国内品牌的服装、鞋子、包包，国外品牌和带国外卡通形象的商品一律不行
部分化妆品：化妆笔、毛刷，另有专门的非液体类化妆品专线请咨询客服
非医用防疫物资：有专线可以走请咨询客服
          </p>
          <p>
            尺寸要求：任何最长边不要超过55，次长边不要超过50。如果有尺寸比较大的包裹，请单独咨询客服。
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
          所有国外品牌和带国外卡通形象的商品，带医用字样的防疫用品（口罩、防护服等），腐蚀性，酒精，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），不适合邮递条件的物品，包括液体（如酒、化妆水等）、乳霜类化妆品（乳霜、眼霜、睫毛膏、牙膏等）、颗粒、粉末、食品、茶叶、中药、西药、香烟、带电池的（干电池，锂电池/可充电电池等）、充电宝、磁铁（包括带有电机、喇叭和其他有磁性的物品，如冰箱贴）、有攻击性的用具（如菜刀、剪刀）等，以及A货，金项链，玉器等贵重饰品，蓝牙产品（蓝牙玩具，蓝牙耳机、蓝牙键盘，除非是可以拆除的干电池）
          </p>
        </div>
      </Card>

      <Card bordered={false} title="(美国)快递普货/敏感(支持混装)" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/5000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
            该线路为空运快递，支持普货/敏感混装，普货规则参考上面空运普货，敏感方面：可运有正规包装的干货，如茶叶、辣条、干果、干的菌菇类、干木耳、干豆角、干花椒、海鲜干货、干人参等
          </p>
          <p>
            尺寸要求：任何最长边不要超过55，次长边不要超过50。如果有尺寸比较大的包裹，请单独咨询客服。
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
          带医用字样的防疫用品（口罩、防护服等），腐蚀性，酒精，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），带酱料包的方便面，任何形式的肉类（鸡、鸭、猪、牛、羊等）、燕窝、鸡蛋类等。任何颜色粉末颗粒、白色食品、新鲜肉类、新鲜果蔬、冷冻食品、自热火锅、各种带油带水调料（如老干妈、火锅底料、辣椒酱等）、蜂蜜（含液体蜂蜜）、液体（麻油、醋、酱油等）、阿胶、大米、烟、酒、中药、艾叶、西药、减肥药等
          </p>
        </div>
      </Card>

      <Card bordered={false} title="(美国)空运药品" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/6000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
          可接各类药品，保健品。
          </p>
          <p>
            尺寸要求：任何最长边不要超过55，次长边不要超过50。如果有尺寸比较大的包裹，请单独咨询客服。
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
          仿牌，电磁产品，腐蚀性，酒精，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪）等具有严格管控性和危险性的产品。
          </p>
        </div>
      </Card>

      <Card bordered={false} title="(加拿大)快递普货/敏感(支持混装)" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/5000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
          普货类产品和有正规包装的干货，如茶叶、辣条、干果、干的菌菇类、干木耳、干豆角、干花椒、海鲜干货、干人参等
          </p>
          <p>
            尺寸要求：最长边不要超过55，次长边不要超过50。如果有尺寸比较大的包裹，请单独咨询客服，由客服分配到指定团，或者直邮发出。
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
          带医用字样的防疫用品（口罩、防护服等），腐蚀性，酒精，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），带酱料包的方便面，任何形式的肉类（鸡、鸭、猪、牛、羊等）、燕窝、鸡蛋类等。任何颜色粉末颗粒、白色食品、新鲜肉类、新鲜果蔬、冷冻食品、自热火锅、各种带油带水调料（如老干妈、火锅底料、辣椒酱等）、蜂蜜（含液体蜂蜜）、液体（麻油、醋、酱油等）、阿胶、大米、烟、酒、中药、艾叶、西药、减肥药等          </p>
        </div>
      </Card>

      <Card bordered={false} title="(日本)家乡包裹" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/6000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
          普货类商品+部分药品+部分化妆品+食品干货。
          </p>
          <p>
            尺寸要求：任何最长边不要超过55，次长边不要超过50。如果有尺寸比较大的包裹，请单独咨询客服。单票限重30kg。
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
          液体粉末状的任意物品食品药品和化妆品。（不可批量寄单一产品，需和其他产品混合发出，且不超过个人正常使用量，否则会被日本海关强制征收商品进口税，由收件人支付。）带医用字样的防疫用品（口罩、防护服等），腐蚀性，酒精，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），带酱料包的方便面，任何形式的肉类（鸡、鸭、猪、牛、羊等）、燕窝、鸡蛋类等。任何颜色粉末颗粒、白色食品、新鲜肉类、新鲜果蔬、冷冻食品、自热火锅、各种带油带水调料（如老干妈、火锅底料、辣椒酱等）、蜂蜜（含液体蜂蜜）、液体（麻油、醋、酱油等）、阿胶、大米、烟、酒、中药、艾叶、西药、减肥药等
          </p>
        </div>
      </Card>
    </div>
  );
}

export default Contraband;
